import {DOCUMENT} from '@angular/common';
import {
    Component,
    computed,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    Signal,
    signal,
    WritableSignal
} from '@angular/core';
import {SidebarItemComponent} from 'src/app/components/utilities/sidebar-item/sidebar-item.component';
import {GoogleIcon} from 'src/app/enums/core/google-icon.enum';
import {RouteData} from 'src/app/interfaces/core/route-data.interface';
import {SidebarItem} from 'src/app/interfaces/core/sidebar-item.interface';
import {ButtonsModule} from 'src/app/modules/buttons/buttons.module';
import {generateRandomString} from 'src/app/utilities/string.util';

const RAD_STANDALONE_COMPONENTS = [
    SidebarItemComponent
];

const RAD_MODULES = [
    ButtonsModule
];

@Component({
    selector: 'app-sidebar',
    standalone: true,
    imports: [
        ...RAD_STANDALONE_COMPONENTS,
        ...RAD_MODULES
    ],
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
    @Input({required: true}) public routeData!: RouteData;

    @Input() public items?: SidebarItem[];

    @Input() public pathIndex: number = 0;
    @Input() public indent: boolean = false;
    @Input() public firstLevelHorizontalLayout: boolean = false;

    @Output() public itemClick: EventEmitter<SidebarItem> = new EventEmitter<SidebarItem>();
    @Output() public navigate: EventEmitter<string> = new EventEmitter<string>();

    protected firstLevelHorizontalLayoutFinalSignal!: Signal<boolean>;
    protected _itemsSignal: WritableSignal<SidebarItem[]> = signal([]);
    protected id: string = generateRandomString(6);

    protected readonly GoogleIcon = GoogleIcon;

    constructor(
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    public ngOnInit() {
        this.firstLevelHorizontalLayoutFinalSignal = computed(() => this.firstLevelHorizontalLayout && this._itemsSignal()
                                                                                                           .some(entry => entry.items));
    }

    protected handleItemClick(item: SidebarItem) {
        const element = this.document.getElementById(this.id + '_' + item.key);
        if (element) element.scrollIntoView();

        if (this.pathIndex) {
            this.itemClick.emit(item);
        } else if (item.command) {
            item.command();
        } else if (item.items) {
            if (this.firstLevelHorizontalLayoutFinalSignal()) {
                this.expandAsSelect(item);
            } else {
                this.expand(item);
            }
        } else {
            this.onNavigate(item);
        }
    }

    private expand(item: SidebarItem) {
        item.expanded = !item.expanded;
    }

    private expandAsSelect(item: SidebarItem) {
        this.items?.forEach(entry => entry.expanded = entry === item);
    }

    private onNavigate(item: SidebarItem) {
        const routerLink = item.routerLink;
        if (!routerLink) return;

        this.navigate.emit(routerLink);
    }
}