import {Component, Input} from '@angular/core';
import {DialogsService} from 'src/app/services/core/dialogs.service';

@Component({
    selector: 'app-ui-blocker',
    standalone: true,
    imports: [],
    templateUrl: './ui-blocker.component.html'
})
export class UiBlockerComponent {
    @Input() styleClass: string = '';
    @Input() centerX: boolean | null = true;
    @Input() centerY: boolean | null = true;
    @Input() block?: boolean | null;

    constructor(
        protected dialogsService: DialogsService
    ) {
    }
}