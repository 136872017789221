import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DividerModule} from 'primeng/divider';
import {MobileMenuItemComponent} from 'src/app/components/utilities/mobile-menu-item/mobile-menu-item.component';
import {GoogleIcon} from 'src/app/enums/core/google-icon.enum';
import {Severity} from 'src/app/enums/core/severity.enum';
import {UserDto} from 'src/app/interfaces/backend/dto/user-dto.interface';
import {MobileMenuItem} from 'src/app/interfaces/core/mobile-menu-item.interface';
import {RouteData} from 'src/app/interfaces/core/route-data.interface';
import {SidebarItem} from 'src/app/interfaces/core/sidebar-item.interface';
import {ButtonsModule} from 'src/app/modules/buttons/buttons.module';
import {generateRandomString} from 'src/app/utilities/string.util';

const RAD_STANDALONE_COMPONENTS = [
    MobileMenuItemComponent
];

const RAD_MODULES = [
    ButtonsModule
];

const PRIMEN_NG_MODULE = [
    DividerModule
];

@Component({
    selector: 'app-mobile-menu',
    standalone: true,
    imports: [
        ...RAD_STANDALONE_COMPONENTS,
        ...RAD_MODULES,
        ...PRIMEN_NG_MODULE
    ],
    templateUrl: './mobile-menu.component.html'
})
export class MobileMenuComponent {
    @Input() public userProfile?: UserDto | null;
    @Input({required: true}) public routeData!: RouteData;
    @Input() public items: MobileMenuItem[] = [];
    @Input() public notificationsCount?: number;
    @Input() public pathIndex: number = 0;
    @Input() public indent: boolean = false;

    @Output() public itemClick: EventEmitter<SidebarItem> = new EventEmitter<SidebarItem>();
    @Output() public navigate: EventEmitter<string> = new EventEmitter<string>();
    @Output() public openHelpForm: EventEmitter<void> = new EventEmitter<void>();
    @Output() public openNotifications: EventEmitter<void> = new EventEmitter<void>();
    @Output() public changeTheme: EventEmitter<void> = new EventEmitter<void>();
    @Output() public logout: EventEmitter<void> = new EventEmitter<void>();

    protected id: string = generateRandomString(6);

    protected readonly GoogleIcon = GoogleIcon;
    protected readonly Severity = Severity;

    protected get activeItem() {
        return this.items.find(entry => entry.expanded);
    }

    protected handleItemClick(item: MobileMenuItem) {
        if (this.pathIndex) {
            this.itemClick.emit(item);
        } else if (item.command) {
            item.command();
        } else if (item.items) {
            this.expand(item);
        } else {
            this.onNavigate(item);
        }
    }

    private expand(item: MobileMenuItem) {
        item.expanded = !item.expanded;
    }

    protected onItemClick() {
        this.itemClick.emit();
    }

    private onNavigate(item: MobileMenuItem) {
        const routerLink = item.routerLink;
        if (!routerLink) return;

        this.onItemClick();
        this.navigate.emit(routerLink);
    }

    protected onOpenNotifications() {
        this.openNotifications.emit();
    }

    protected onOpenHelpForm() {
        this.openHelpForm.emit();
    }

    protected onChangeTheme() {
        this.changeTheme.emit();
    }

    protected onLogout() {
        this.logout.emit();
    }
}