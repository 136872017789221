@let doesZusCertPathExist = profileFacade.userProfileSignal()?.usType !== Role.DOCTOR || profileFacade.userProfileSignal()?.zusCertPath ;
@let loading = (globalStateDelayed$ | async) === State.LOADING ;

<div class="rad-background"></div>

@if (doesZusCertPathExist) {
    <div class="rad-grid-container"
         id="rad-root">
        <div class="rad-grid-mobilemenu p-2 d-lg-none rad-background-color-sidebar text-end position-sticky top-0 rad-border-divider-bottom"
             style="z-index: 1">
            <div class="position-relative w-min-content ms-auto">
                <p-button styleClass="p-button-icon-only"
                          [size]="Size.SMALL"
                          [outlined]="true"
                          (onClick)="mobileMenuVisible = true">
                    <app-google-icon [icon]="GoogleIcon.MENU"></app-google-icon>
                </p-button>

                @if (notificationsFacade.unreadNotificationsCount) {
                    <p-badge styleClass="p-badge-dot"
                             [severity]="Severity.DANGER"></p-badge>
                }
            </div>
        </div>

        @if (coreService.routeData) {
            <div class="rad-grid-navbar d-below-lg-none p-2 position-sticky top-0 overflow-y-auto rad-background-color-navbar rad-border-divider-right">
                <app-navbar [routeData]="coreService.routeData"
                            [items]="navbarService.navbar"
                            [userProfile]="profileFacade.userProfileSignal()"
                            [notificationsCount]="notificationsFacade.unreadNotificationsCount"
                            [currentLanguage]="languageService.language$ | async"
                            [indent]="true"
                            (navigate)="onNavigate($event)"
                            (openNotifications)="onOpenNotifications()"
                            (openHelpForm)="onOpenHelpForm()"
                            (changeLanguage)="onChangeLanguage()"
                            (changeTheme)="onChangeTheme()"
                            (logout)="onLogout()"></app-navbar>
            </div>
        }

        @if (coreService.routeData && navbarService.sidebar.length) {
            <div class="rad-grid-sidebar d-below-lg-none p-2 position-sticky top-0 overflow-y-auto rad-background-color-sidebar rad-border-divider-right"
                 style="max-width: 230px">
                <app-sidebar [routeData]="coreService.routeData"
                             [items]="navbarService.sidebar"
                             [indent]="true"
                             (navigate)="onNavigate($event)"></app-sidebar>
            </div>
        }

        <div class="rad-grid-wrapper"
             [class]="{'position-relative': loading && !dialogsService.openDialogs.size}">
            @if (helperTopbarService.helperTopbarElements.length || (helperTopbarService.helperTopbarMenuItems$ | async)?.length) {
                <div class="rad-grid-helper-topbar p-1 overflow-x-hidden rad-background-color-helper-topbar rad-border-divider-bottom">
                    <app-helper-topbar></app-helper-topbar>
                </div>
            }

            <div class="rad-grid-content overflow-x-auto rad-background-color-content">
                @if (navigationState$ | async) {
                    <div class="container-fluid"
                         [class]="PADDING_Y">
                        <div [class]="MARGIN_SECTION_BOTTOM">
                            <app-breadcrumbs></app-breadcrumbs>
                        </div>
                        <router-outlet></router-outlet>
                    </div>
                }
            </div>

            @if (bottombarService.bottombarElements.length || bottombarService.component) {
                <div class="rad-grid-bottombar p-2 overflow-x-auto rad-background-color-helper-topbar rad-border-divider-top">
                    <app-bottombar></app-bottombar>
                </div>
            }

            <app-ui-blocker [block]="loading">
                <p-progressSpinner styleClass="rad-progress-spinner"></p-progressSpinner>
            </app-ui-blocker>
        </div>
    </div>

    @if (coreService.routeData) {
        <p-sidebar styleClass="rad-background-color-sidebar"
                   [visible]="mobileMenuVisible"
                   (visibleChange)="closeMobileMenu($event)"
                   [fullScreen]="true">
            <app-mobile-menu [routeData]="coreService.routeData"
                             [items]="mobileMenuService.mobileMenu"
                             [userProfile]="profileFacade.userProfileSignal()"
                             [notificationsCount]="notificationsFacade.unreadNotificationsCount"
                             [indent]="true"
                             (itemClick)="mobileMenuVisible = false"
                             (navigate)="onNavigate($event)"
                             (openNotifications)="onOpenNotifications()"
                             (openHelpForm)="onOpenHelpForm()"
                             (changeTheme)="onChangeTheme()"
                             (logout)="onLogout()"></app-mobile-menu>
        </p-sidebar>
    }

    <app-dialog width="1360px"
                header="submitTicket"
                [visible]="helpFormService.formModel"
                (xClose)="helpFormService.formModel = null">
        @if (helpFormService.formModel) {
            <app-help-form></app-help-form>
        }
    </app-dialog>

    <p-sidebar styleClass="rad-background-color-content"
               [(visible)]="notificationsFacade.displayNotificationsSidebar">
        <div class="pt-1">
            <app-notifications></app-notifications>
        </div>
    </p-sidebar>

    <app-dialog [(visible)]="notificationsFacade.displayNotificationsDialog"
                width="1360px"
                header="notifications"
                #notificationsDialog>
        @if (notificationsDialog.visible()) {
            <div class="pt-1">
                <app-notifications [dialogMode]="true"></app-notifications>
            </div>
        }
    </app-dialog>

    <app-dialog [(visible)]="displayKeyboardShortcutsHelp"
                maxWidth="800px"
                header="keyboardShortcuts">
        <app-keyboard-shortcuts [readOnly]="true"></app-keyboard-shortcuts>
    </app-dialog>
}

<app-dialog width="1360px"
            header="zusCertificateRequired"
            [visible]="!doesZusCertPathExist"
            (xClose)="onLogout()">
    <app-zus-certificate-upload (zusCertificateUploaded)="reloadPage()"></app-zus-certificate-upload>
</app-dialog>

<app-confirm-delete-dialog [(visible)]="confirmDeleteDialogService.visible"
                           [(subjectContext)]="confirmDeleteDialogService.subjectContext"
                           [(subject)]="confirmDeleteDialogService.subject"
                           (confirm)="confirmDeleteDialogService.onConfirm()"></app-confirm-delete-dialog>

<app-confirm-dialog [(visible)]="confirmDialogService.visible"
                    [header]="confirmDialogService.header"
                    [bodyContent]="confirmDialogService.bodyContent"
                    [confirmButtonSeverity]="confirmDialogService.confirmButtonSeverity"
                    (confirm)="confirmDialogService.onConfirm()"></app-confirm-dialog>

<app-info-dialog [(visible)]="infoDialogService.visible"
                 [header]="infoDialogService.header"
                 [bodyContent]="infoDialogService.bodyContent"
                 [confirmButtonLabel]="infoDialogService.confirmButtonLabel"
                 [noCloseOnConfirm]="infoDialogService.noCloseOnConfirm"
                 (confirm)="infoDialogService.onConfirm()"></app-info-dialog>

<p-contextMenu [model]="(contextMenuService.contextMenuItems$ | async) ?? []"
               [autoZIndex]="true"
               [baseZIndex]="10005"></p-contextMenu>

<p-menu [model]="(contextMenuService.contextMenuItems$ | async) ?? []"
        appendTo="body"
        [popup]="true"
        [autoZIndex]="true"></p-menu>

<p-toast></p-toast>

@if (environment.DEV_MODE) {
    <app-profile-manager></app-profile-manager>
}