@if (notification) {
    <p-card [styleClass]="[
        'rad-card-notification',
        !notification.isRead && 'rad-card-notification-unread'
        ].join(' ')"
            class="cursor-pointer"
            (click)="onGoToSubject(notification)">

        <ng-template pTemplate="header">
            @if (!notification.isRead) {
                <p-button class="text-end"
                          [link]="true"
                          [size]="Size.SMALL"
                          [label]="'markAsRead' | translate"
                          (onClick)="onMarkAsRead($event, notification)"></p-button>
            }
        </ng-template>

        <div class="d-flex flex-column gap-4">
            @if (notification.procedure || notification.radPriority) {
                <div class="d-flex gap-2 align-items-center">
                    @if (notification.radPriority; as priority) {
                        <p-tag [severity]="PRIORITY_SEVERITY_MAP[priority]"
                               [value]="'enums.RadPriority.' + priority | translate"></p-tag>
                    }

                    @if (notification.procedure) {
                        <span [class]="notification.isRead ? 'rad-text-label' : 'rad-text-small-bold'">{{ dialogMode ? notification.procedure : (notification.procedure | textShortener: 100) }}</span>
                    }
                </div>
            }

            <span [class]="notification.isRead ? 'rad-text-color-label' : 'rad-text-normal-bold'">{{ dialogMode ? notification.content : (notification.content | textShortener: 100) }}</span>

            <div class="d-flex flex-column text-end"
                 [class]="notification.isRead ? 'rad-text-label' : 'rad-text-small-bold'">
                <div>{{ notification.notificationDate | date: DATE_TIME_FORMAT }}</div>
                <div>{{ notification.senderName }}</div>
            </div>
        </div>
    </p-card>
}