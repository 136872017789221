import {Injectable, Injector} from '@angular/core';
import {HelpFormData} from 'src/app/models/forms/help-form.model';
import {RadiologyControllerService} from 'src/app/services/backend/radiology-controller.service';
import {displaySuccess} from 'src/app/utilities/toast.util';

@Injectable({providedIn: 'root'})
export class HelpFacade {

    constructor(
        private radiologyControllerService: RadiologyControllerService,
        private injector: Injector
    ) {
    }

    public getHelpSubjects() {
        return this.radiologyControllerService.getHelpSubjects();
    }

    public send(formData: HelpFormData, callback: () => void) {
        this.radiologyControllerService.sendHelpEmail(formData).subscribe((data) => {
            displaySuccess(this.injector);
            callback();
        });
    }
}