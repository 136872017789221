<div class="d-flex flex-column gap-2 h-100">
    @for (item of items; track item.key) {
        <app-navbar-item [item]="item"
                         (itemClick)="onItemClick(item)"></app-navbar-item>
        @if (item.items && item.expanded) {
            <div [class]="{
            'mb-2': item.expanded && ($index + 1) < items.length,
            'ps-6': indent
            }">
                <app-navbar [routeData]="routeData"
                            [items]="item.items"
                            [pathIndex]="pathIndex + 1"
                            [indent]="indent"></app-navbar>
            </div>
        }
    }

    @if (!pathIndex) {
        <div class="d-flex flex-column gap-2 mt-auto">
            @if (userProfile) {
                <app-navbar-item [icon]="GoogleIcon.NOTIFICATIONS"
                                 [notificationsCount]="notificationsCount"
                                 label="notifications"
                                 (itemClick)="onOpenNotifications()"></app-navbar-item>

                <app-navbar-item [icon]="GoogleIcon.HELP"
                                 label="help"
                                 (itemClick)="onOpenHelpForm()"></app-navbar-item>
            }


            <app-navbar-item [icon]="GoogleIcon.LANGUAGE"
                             [label]="currentLanguage ?? ''"
                             (itemClick)="onChangeLanguage()"></app-navbar-item>

            <app-navbar-item [icon]="GoogleIcon.CONTRAST"
                             label="changeTheme"
                             (itemClick)="onChangeTheme()"></app-navbar-item>

            @if (userProfile) {
                <app-navbar-item [icon]="GoogleIcon.LOGOUT"
                                 label="logout"
                                 [severity]="Severity.DANGER"
                                 [outlined]="false"
                                 (itemClick)="onLogout()"></app-navbar-item>
            }
        </div>
    }
</div>