<div class="d-flex flex-column rad-mobile-menu"
     [class]="{'h-100': !pathIndex}">
    @for (item of items; track item.key) {
        <app-mobile-menu-item [item]="item"
                              (itemClick)="handleItemClick(item)"></app-mobile-menu-item>
        @if (item.items) {
            @if (item.expanded)  {
                <div [class]="{
                'pe-7': indent
                }">
                    <app-mobile-menu [routeData]="routeData"
                                     [items]="item.items"
                                     [pathIndex]="pathIndex + 1"
                                     [indent]="indent"
                                     (itemClick)="handleItemClick($event)"></app-mobile-menu>
                </div>
            }
        }
    }

    @if (!pathIndex) {
        <div class="d-flex flex-column mt-auto">
            @if (userProfile) {
                <app-mobile-menu-item [icon]="GoogleIcon.NOTIFICATIONS"
                                      [notificationsCount]="notificationsCount"
                                      label="notifications"
                                      (itemClick)="onOpenNotifications()"></app-mobile-menu-item>

                <app-mobile-menu-item [icon]="GoogleIcon.HELP"
                                      label="help"
                                      (itemClick)="onOpenHelpForm()"></app-mobile-menu-item>
            }


            <app-mobile-menu-item [icon]="GoogleIcon.CONTRAST"
                                  label="changeTheme"
                                  (itemClick)="onChangeTheme()"></app-mobile-menu-item>

            @if (userProfile) {
                <app-mobile-menu-item [icon]="GoogleIcon.LOGOUT"
                                      label="logout"
                                      [severity]="Severity.DANGER"
                                      [text]="false"
                                      (itemClick)="onLogout()"></app-mobile-menu-item>
            }
        </div>
    }
</div>